import './styles/global.scss';

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { YMInitializer } from 'react-yandex-metrika';

import About from './components/about/About';
import CookieBanner from './components/banner/CookieBanner';
import Home from './components/home/Home';
import Layout from './components/layout/Layout';
import Policy from './components/policy/Policy';

const App = () => {
  return (
    <>
      <YMInitializer accounts={[91114766]} options={{ webvisor: true }} version="2" />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          {/* <Route path="/terms" element={<Terms />} />
          <Route path="/agreement" element={<Agreement />} /> */}
          <Route path="/policy" element={<Policy />} />
          <Route path="/info" element={<About />} />
          <Route path="*" element={<Home />} />
        </Route>
      </Routes>
      <CookieBanner />
    </>
  );
};

export default App;
