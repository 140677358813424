import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import back from '../../assets/about/back.svg';
import paymentSecurity from '../../assets/about/payment_security.pdf';
import paymentShippingReturn from '../../assets/about/payment_shipping_return.pdf';
import processingOfPersonalData from '../../assets/about/processing_of_personal_data.pdf';
import serviceDetails from '../../assets/about/service_details.pdf';
import useAgreementEmployee from '../../assets/about/user_agreement_employee.pdf';
import useAgreementOrganization from '../../assets/about/user_agreement_organization.pdf';
import useAgreementVisitor from '../../assets/about/user_agreement_visitor.pdf';
import userGuideAdminDesktop from '../../assets/about/user_guide_administrator_desktop.pdf';
import userGuideAdminMobile from '../../assets/about/user_guide_administrator_mobile.pdf';
import userGuideEmployee from '../../assets/about/user_guide_employee.pdf';
import userGuideVisitor from '../../assets/about/user_guide_visitor.pdf';
import styles from './about.module.scss';

const About = React.memo(() => {
  useEffect(() => {
    document.documentElement.style.scrollBehavior = 'initial';
    document.body.style.scrollBehavior = 'initial';
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
    return () => {
      document.documentElement.style.scrollBehavior = 'smooth';
      document.body.style.scrollBehavior = 'smooth';
    };
  }, []);
  return (
    <div className={`container ${styles.aboutContainer}`}>
      <div className="container">
        <Link to="/" className={styles.backBtn}>
          <img src={back} alt="back" />
          <span type="button">Назад</span>
        </Link>
        <div className={styles.infoLinks}>
          <a download="Политика обработки персональных данных.pdf" href={processingOfPersonalData}>
            Политика обработки персональных данных
          </a>
          <a download="Политика безопасности платежей.pdf" href={paymentSecurity}>
            Политика безопасности платежей
          </a>
          <a download="Оплата, доставка и возврат товара.pdf" href={paymentShippingReturn}>
            Оплата, доставка и возврат товара
          </a>
          <a
            download="Пользовательское соглашение - Заведение (Администратор).pdf"
            href={useAgreementOrganization}
          >
            Пользовательское соглашение - Заведение (Администратор)
          </a>
          <a download="Пользовательское соглашение - Сотрудник.pdf" href={useAgreementEmployee}>
            Пользовательское соглашение - Сотрудник
          </a>
          <a
            download="Пользовательское соглашение - Посетитель заведения.pdf"
            href={useAgreementVisitor}
          >
            Пользовательское соглашение - Посетитель заведения
          </a>
          <a
            download="Руководство пользователя - Заведение (Администратор) для web-версии.pdf"
            href={userGuideAdminDesktop}
          >
            Руководство пользователя - Заведение (Администратор) для web-версии
          </a>
          <a
            download="Руководство пользователя - Заведение (Администратор) для mobile-версии.pdf"
            href={userGuideAdminMobile}
          >
            Руководство пользователя - Заведение (Администратор) для mobile-версии
          </a>
          <a download="Руководство пользователя - Сотрудник.pdf" href={userGuideEmployee}>
            Руководство пользователя - Сотрудник
          </a>
          <a download="Руководство пользователя - Посетитель заведения.pdf" href={userGuideVisitor}>
            Руководство пользователя - Посетитель заведения
          </a>
          <a download="Реквизиты Сервиса.pdf" href={serviceDetails}>
            Реквизиты Сервиса
          </a>
        </div>
      </div>
    </div>
  );
});

About.displayName = 'About';

export default About;
